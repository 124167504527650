import React from "react";

export const VideoPlay = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="40.946"
      height="52.645"
      viewBox="0 0 40.946 52.645"
    >
      <path d="M26.323,0,52.645,40.946H0Z" transform="translate(40.946 0) rotate(90)"></path>
    </svg>
  );
};
