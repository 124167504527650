import React, { FunctionComponent, useContext, useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import sanityImageUrlBuilder from "web-common/src/utils/imageUrlBuilder";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import { LocalizedContext } from "web-common/src/services/LocalizedContextService";
import { VideoPause } from "../../images/icons/videoPause";
import { VideoPlay } from "../../images/icons/videoPlay";
import Accessibility from "../Accessibility";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Lazy, Autoplay, EffectFade, Pagination, A11y } from "swiper";

import "./styles.scss";
import { event80 } from "../../analytics/event80";

const Carousel: FunctionComponent = ({ _rawSlides, index, _id }) => {
  const { pageLanguage } = useContext(LocalizedContext);
  const { sanityId, sanityDataset } = useSiteMetadata(pageLanguage);
  const [isPlaying, setIsPlaying] = useState(true);
  const [videoButtonlabel, setVideoButtonlabel] = useState("Pause");
  const componentIndexOnPage = index;

  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const videoBtnRefs = useRef([]);
  const videoRefs = useRef([]);
  const carouselCopyRefs = useRef([]);
  const carouselCTARefs = useRef([]);

  useEffect(() => {
    const autoPlayEvent = (e: any) => {
      setTimeout(() => {
        event80(e.target?.title, e.target?.src);
      }, 1000);
      e.target.removeEventListener("play", autoPlayEvent);
    };

    videoRefs.current.map((video, index) => {
      videoRefs.current[index].addEventListener("play", autoPlayEvent);
    });

    return () => {
      videoRefs.current.map((video, index) => {
        videoRefs.current[index].removeEventListener("play", autoPlayEvent);
      });
    };
  }, []);

  const handleSlideChange = event => {
    const activeSlide = carouselCopyRefs.current[event.activeIndex];
    const activeCTA = carouselCTARefs.current[event.activeIndex];
    if (event.slides[event.activeIndex].classList.contains("is-video")) {
      if (videoRefs.current[event.activeIndex - 1]) {
        videoRefs.current[event.activeIndex - 1].pause();
        setIsPlaying(false);
      }
      videoRefs.current[event.activeIndex].currentTime = 0;
      videoRefs.current[event.activeIndex].play();
      setIsPlaying(true);
    }
    if (event.activeIndex === 0) {
      carouselCopyRefs.current[event.slides.length - 1].classList.remove("is-animating");
      carouselCTARefs.current[event.slides.length - 1].classList.remove("is-animating");
    } else {
      if (activeSlide && activeCTA) {
        carouselCopyRefs.current[event.activeIndex - 1]?.classList?.remove("is-animating");
        carouselCTARefs.current[event.activeIndex - 1]?.classList?.remove("is-animating");
      }
    }
  };

  const handleVideoPlayPause = (ev, idx) => {
    if (videoRefs.current[idx].paused) {
      videoRefs.current[idx].play();
      setIsPlaying(true);
      setVideoButtonlabel(videoBtnRefs.current[idx].dataset.pause);
    } else {
      videoRefs.current[idx].pause();
      setIsPlaying(false);
      setVideoButtonlabel(videoBtnRefs.current[idx].dataset.play);
    }
  };

  const handleSwiperInitialize = event => {
    if (carouselCopyRefs.current[0] && carouselCTARefs.current[0]) {
      carouselCopyRefs.current[0].classList.add("is-animating");
      carouselCTARefs.current[0].classList.add("is-animating");
    }
    if (videoRefs.current[0]) {
      videoRefs.current[0].play();
      setIsPlaying(true);
    }
  };

  const handleBeforeSlideChangeStart = event => {
    const slide = carouselCopyRefs.current[event.activeIndex];
    const slideCTA = carouselCTARefs.current[event.activeIndex];
    if (slide && slideCTA) {
      slide.classList.add("is-animating");
      slideCTA.classList.add("is-animating");
    }
  };

  const Heading = ({ headingLevel, children }) => {
    const Heading = headingLevel;
    return <Heading className="carousel-copy--title">{children}</Heading>;
  };

  const carouselSlides = (slide, index, urlBuilder) => {
    return (
      <SwiperSlide key={slide.name} className={slide.video && "is-video"}>
        <div
          className="carousel-image"
          style={{
            background: `url(${slide?.image?.asset?.metadata?.lqip})`,
            backgroundSize: "cover"
          }}
        >
          {slide.video ? (
            <>
              <video
                ref={el => (videoRefs.current[index] = el)}
                src={slide.video.asset.url}
                playsInline
                muted
                loop
                preload="metadata"
                type="video/mp4"
                title={slide.name}
              >
                <p>
                  Your browser doesn't support HTML5 video. Here is a{" "}
                  <a href={slide.video.asset.url}>link to the video</a> instead.
                </p>
              </video>
              <button
                ref={el => (videoBtnRefs.current[index] = el)}
                data-play="Play"
                data-pause="Pause"
                onClick={ev => handleVideoPlayPause(ev, index)}
                className={"btn-playPause"}
              >
                {isPlaying ? <VideoPause /> : <VideoPlay />}
                <Accessibility location={videoButtonlabel} />
              </button>
            </>
          ) : (
            <picture
              data-testid="product-image"
              className="placeholder"
              style={{
                background: `url(${slide?.image?.asset?.metadata?.lqip})`,
                backgroundSize: "cover"
              }}
            >
              <source
                media="screen and (min-width: 576px)"
                srcSet={`${urlBuilder.image(slide.image).auto("format").quality(80).fit("max").url()}`}
              />
              <img
                className="carousel-image-item"
                src={urlBuilder.image(slide.image).auto("format").quality(80).width(540).height(520).fit("max").url()}
                loading={"lazy"}
                alt={slide.image?.alt}
              />
            </picture>
          )}
        </div>
        <div className="carousel-copy">
          <Container fluid>
            <div className="row">
              <div className="col-xs-12">
                <div
                  className={`carousel-copy--wrapper is-animate is-animate--left is-animating`}
                  ref={el => (carouselCopyRefs.current[index] = el)}
                >
                  {slide.headline.primaryText && (
                    <p className="carousel-copy--subtitle">{slide.headline.primaryText}</p>
                  )}
                  <Heading headingLevel={componentIndexOnPage === 0 && index === 0 ? "h1" : "h2"}>
                    {slide.headline.secondaryText && <span>{slide.headline.secondaryText}</span>}
                    {slide.headline.tertiaryText && <span>{slide.headline.tertiaryText}</span>}
                  </Heading>
                  {/* TODO:Insert introduction */}
                </div>
                {slide.ctaLabel && (
                  <Link
                    ref={el => (carouselCTARefs.current[index] = el)}
                    className={`btn-primary is-animate is-animate--right`}
                    to={`${
                      slide.ctaReference?.slug.current && slide.blockReference?._id
                        ? slide.ctaReference?.slug.current && "#" + slide.blockReference._id
                        : slide.ctaReference?.slug.current
                    }`}
                    id={`carouselCallToAction${index}`}
                  >
                    {slide.ctaLabel}
                  </Link>
                )}
              </div>
            </div>
          </Container>
        </div>
      </SwiperSlide>
    );
  };

  SwiperCore.use([Lazy, EffectFade, Autoplay, Pagination, A11y]);

  return (
    <section className={`carousel ${index === 0 ? "first" : ""} ${_id}`}>
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: true
        }}
        effect="fade"
        pagination={{ clickable: true }}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        onSwiper={handleSwiperInitialize}
        onSlideChangeTransitionStart={handleBeforeSlideChangeStart}
        speed={700}
        threshold={5}
        lazy={true}
        preloadImages={true}
        watchSlidesVisibility
        watchSlidesProgress
        watchOverflow={true}
      >
        {_rawSlides.map((slide: any, index: number) => {
          return carouselSlides(slide, index, urlBuilder);
        })}
      </Swiper>
    </section>
  );
};
export default Carousel;
