import React from "react";

export const VideoPause = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <title>Video pause home</title>
      <path d="M256 0c-141.385 0-256 114.615-256 256s114.615 256 256 256 256-114.615 256-256-114.615-256-256-256zM256 464c-114.875 0-208-93.125-208-208s93.125-208 208-208 208 93.125 208 208-93.125 208-208 208zM160 160h64v192h-64zM288 160h64v192h-64z"></path>
    </svg>
  );
};
